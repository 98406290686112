import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import styled from "styled-components"
import Img from "gatsby-image"
import { graphql, Link } from "gatsby"

import { OutboundLink } from "gatsby-plugin-google-analytics"

const IndexPage = props => (
  <Layout>
    <SEO
      title="Pierre Rossignol - Freelance Creative Technologist based in New York"
      description="I'm a freelance Creative Technologist based in New York. I develop immersive digital experiences, marketing websites, product web and mobile apps."
      keywords={[
        `freelance developer`,
        `front-end developer`,
        `react developer`,
        `creative technologist`,
        `product developer`,
      ]}
    />

    <MainWrapper>
      <Text>
        <span>I'm a freelance Creative Technologist based in New York.</span> I
        develop immersive digital experiences, marketing websites, product web
        and mobile apps. I've worked for Adidas, Volkswagen, EA, Warner Bros.
      </Text>
      <Text>
        <Link to="/about">
          <span>More about me</span>
        </Link>
      </Text>
    </MainWrapper>

    <ProjectsList>
      <ProjectItem
        href="https://creative.squarespace.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ProjectPhoto>
          <Img fluid={props.data.creative.childImageSharp.fluid} />
        </ProjectPhoto>
        <ProjectContent>
          <ProjectClient>Squarespace</ProjectClient>
          <ProjectTitle>Creative Dept.</ProjectTitle>
        </ProjectContent>
      </ProjectItem>
      <ProjectItem
        href="https://dreamers.squarespace.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ProjectPhoto>
          <Img fluid={props.data.makers.childImageSharp.fluid} />
        </ProjectPhoto>
        <ProjectContent>
          <ProjectClient>Squarespace</ProjectClient>
          <ProjectTitle>Makers & Dreamers</ProjectTitle>
        </ProjectContent>
      </ProjectItem>
      <ProjectItem
        href="https://pride.squarespace.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ProjectPhoto>
          <Img fluid={props.data.pride.childImageSharp.fluid} />
        </ProjectPhoto>
        <ProjectContent>
          <ProjectClient>Squarespace</ProjectClient>
          <ProjectTitle>
            Squarespace <span>x Pride 2019</span>
          </ProjectTitle>
        </ProjectContent>
      </ProjectItem>
      <ProjectItem
        href="http://www.ddb.fr/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ProjectPhoto>
          <Img fluid={props.data.ddb.childImageSharp.fluid} />
        </ProjectPhoto>
        <ProjectContent>
          <ProjectClient>DDB° PARIS</ProjectClient>
          <ProjectTitle>
            Agency <span>website</span>
          </ProjectTitle>
        </ProjectContent>
      </ProjectItem>
      <ProjectItem
        href="https://youtu.be/uBjqI71Gak4"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ProjectPhoto>
          <Img fluid={props.data.vw.childImageSharp.fluid} />
        </ProjectPhoto>
        <ProjectContent>
          <ProjectClient>Volkswagen</ProjectClient>
          <ProjectTitle>
            Volkswagen <span>Electric</span>
          </ProjectTitle>
        </ProjectContent>
      </ProjectItem>
      <ProjectItem
        href="https://work.firstborn.com/work/featured/328/adidas-nmd"
        rel="noopener noreferrer"
      >
        <ProjectPhoto>
          <Img fluid={props.data.adidas.childImageSharp.fluid} />
        </ProjectPhoto>
        <ProjectContent>
          <ProjectClient>Adidas NMD</ProjectClient>
          <ProjectTitle>
            Streetview <span>experience</span>
          </ProjectTitle>
        </ProjectContent>
      </ProjectItem>
      <ProjectItem
        href="http://wildlife.la/battlefield-hardline-360-heist"
        rel="noopener noreferrer"
      >
        <ProjectPhoto>
          <Img fluid={props.data.battlefield.childImageSharp.fluid} />
        </ProjectPhoto>
        <ProjectContent>
          <ProjectClient>Electronic Arts</ProjectClient>
          <ProjectTitle>
            Battlefield <span>360° Heist</span>
          </ProjectTitle>
        </ProjectContent>
      </ProjectItem>
      <ProjectItem href="http://ss14.soniaby.com/" rel="noopener noreferrer">
        <ProjectPhoto>
          <Img fluid={props.data.soniaby.childImageSharp.fluid} />
        </ProjectPhoto>
        <ProjectContent>
          <ProjectClient>SONIA BY</ProjectClient>
          <ProjectTitle>
            Pre-Spring <span>Collection</span>
          </ProjectTitle>
        </ProjectContent>
      </ProjectItem>
      <ProjectItem
        href="https://www.youtube.com/watch?v=D9vATUTc218"
        rel="noopener noreferrer"
      >
        <ProjectPhoto>
          <Img fluid={props.data.lglc.childImageSharp.fluid} />
        </ProjectPhoto>
        <ProjectContent>
          <ProjectClient>Wildtouch NGO</ProjectClient>
          <ProjectTitle>
            The History of <span>Climate change</span>
          </ProjectTitle>
        </ProjectContent>
      </ProjectItem>
      <ProjectItem
        href="https://www.youtube.com/watch?v=f-WNLVruqJY"
        rel="noopener noreferrer"
      >
        <ProjectPhoto>
          <Img fluid={props.data.tippex.childImageSharp.fluid} />
        </ProjectPhoto>
        <ProjectContent>
          <ProjectClient>Tipp-ex</ProjectClient>
          <ProjectTitle>
            The Social <span>Book</span>
          </ProjectTitle>
        </ProjectContent>
      </ProjectItem>
      <ProjectItem
        href="https://www.b-reel.com/work/gravity"
        rel="noopener noreferrer"
      >
        <ProjectPhoto>
          <Img fluid={props.data.gravity.childImageSharp.fluid} />
        </ProjectPhoto>
        <ProjectContent>
          <ProjectClient>Warner Bros</ProjectClient>
          <ProjectTitle>
            Gravity <span>Experience</span>
          </ProjectTitle>
        </ProjectContent>
      </ProjectItem>
      <ProjectItem>
        <ProjectPhoto>
          <Img fluid={props.data.antarctica.childImageSharp.fluid} />
        </ProjectPhoto>
        <ProjectContent>
          <ProjectClient>Wildtouch NGO</ProjectClient>
          <ProjectTitle>
            Antarctica <span>expedition</span>
          </ProjectTitle>
        </ProjectContent>
      </ProjectItem>
    </ProjectsList>
  </Layout>
)

const MainWrapper = styled.section`
  width: 100%;
  max-width: 1070px;
  margin: 100px auto 0 auto;

  @media (max-width: 1140px) {
    width: 100%;
    padding: 0 40px;
    max-width: none;
  }
  @media (max-width: 1024px) {
    margin: 70px auto;
  }
  @media (max-width: 960px) {
    margin: 50px auto;
  }
  @media (max-width: 780px) {
    padding: 0 30px;
  }
  @media (max-width: 640px) {
    margin: 30px auto;
  }
  @media (max-width: 460px) {
    margin: 20px auto;
  }
`

const Text = styled.h2`
  font-weight: normal;
  font-size: 42px;
  line-height: 60px;
  color: #000;
  margin: 0;
  padding: 0;

  span {
    display: block;
  }

  a {
    color: #000;
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-top: 30px;

    span {
      transform: translate3d(0, 0, 0);
      transition: all 0.3s ease-out;
    }

    &::before {
      content: "";
      width: 40px;
      height: 2px;
      background-color: #000;
      display: block;
      margin-right: 20px;
      margin-top: 2px;
    }

    &:hover {
      span {
        transform: translate3d(5px, 0, 0);
      }
    }
  }

  @media (max-width: 1140px) {
    font-size: 40px;
    line-height: 60px;
  }
  @media (max-width: 1060px) {
    font-size: 38px;
    line-height: 50px;
  }
  @media (max-width: 1010px) {
    font-size: 36px;
    line-height: 50px;
    a {
      font-size: 24px;
    }
  }
  @media (max-width: 960px) {
    font-size: 32px;
    line-height: 48px;
    a {
      font-size: 22px;
      line-height: 28px;
    }
  }
  @media (max-width: 860px) {
    span {
      display: inline;
    }
  }
  @media (max-width: 640px) {
    font-size: 30px;
    line-height: 46px;
    a {
      font-size: 20px;
      line-height: 28px;
      margin-top: 20px;
    }
  }
  @media (max-width: 540px) {
    font-size: 28px;
    line-height: 42px;
    a {
      font-size: 18px;
      line-height: 24px;
      &::before {
        width: 30px;
      }
    }
  }
  @media (max-width: 500px) {
    font-size: 25px;
    line-height: 38px;
  }
  @media (max-width: 460px) {
    font-size: 24px;
    line-height: 32px;
    a {
      font-size: 17px;
      line-height: 20px;
    }
  }
  @media (max-width: 440px) {
    font-size: 22px;
  }
  @media (max-width: 420px) {
    font-size: 20px;
    line-height: 30px;
    a {
      font-size: 16px;
      &::before {
        width: 25px;
        margin-right: 15px;
      }
    }
  }
`

const ProjectsList = styled.section`
  width: 1140px;
  margin: 100px auto;

  @media (max-width: 1140px) {
    width: 100%;
    padding: 0 40px;
  }
  @media (max-width: 1024px) {
    margin: 90px auto;
  }
  @media (max-width: 960px) {
    margin: 80px auto;
  }
  @media (max-width: 780px) {
    padding: 0 30px;
  }
  @media (max-width: 640px) {
    margin: 70px auto;
  }
  @media (max-width: 460px) {
    margin: 50px auto;
  }
`

const ProjectItem = styled(OutboundLink)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  margin-bottom: 50px;
  text-decoration: none;
  color: #000;
  @media (max-width: 780px) {
    margin-bottom: 30px;
  }
  @media (max-width: 640px) {
    flex-direction: column;
  }
`

const ProjectPhoto = styled.div`
  width: 615px;
  height: 411px;
  display: block;
  margin: 0 20px 0 0;

  img {
    max-width: 100%;
    width: 100%;
    height: auto;
    display: block;
  }

  @media (max-width: 1140px) {
    width: calc(100% - 380px);
    height: auto;
  }
  @media (max-width: 920px) {
    width: 440px;
    height: auto;
  }
  @media (max-width: 780px) {
    width: calc(100% - 280px);
    height: auto;
  }

  @media (max-width: 640px) {
    width: 100%;
    display: block;
    margin-right: 0;
  }
`

const ProjectContent = styled.div`
  width: 415px;
  height: 411px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin: 0 0 0 20px;

  @media (max-width: 1140px) {
    width: 380px;
    height: auto;
  }
  @media (max-width: 920px) {
    width: calc(100% - 440px);
    height: auto;
  }
  @media (max-width: 780px) {
    width: 280px;
    height: auto;
  }

  @media (max-width: 640px) {
    width: 100%;
    margin: 10px 0 0 0;
  }
`

const ProjectClient = styled.h3`
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  text-transform: uppercase;
  margin: 0 0 10px 0;

  @media (max-width: 1140px) {
    font-size: 16px;
    line-height: 30px;
  }
  @media (max-width: 920px) {
    font-size: 15px;
    line-height: 28px;
  }
  @media (max-width: 640px) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
  }
  @media (max-width: 520px) {
    font-size: 13px;
    line-height: 20px;
  }
  @media (max-width: 400px) {
    font-size: 12px;
    line-height: 18px;
  }
`

const ProjectTitle = styled.h4`
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  margin: 0;

  span {
    display: block;
  }

  @media (max-width: 1140px) {
    font-size: 42px;
    line-height: 50px;
  }
  @media (max-width: 920px) {
    font-size: 36px;
    line-height: 40px;
  }
  @media (max-width: 820px) {
    font-size: 32px;
    line-height: 38px;
  }
  @media (max-width: 640px) {
    font-size: 26px;
    line-height: 36px;
    span {
      display: inline;
    }
  }
  @media (max-width: 520px) {
    font-size: 22px;
    line-height: 32px;
  }
  @media (max-width: 400px) {
    font-size: 20px;
    line-height: 30px;
  }
`

export const query = graphql`
  query {
    creative: file(relativePath: { eq: "projects/creative.jpg" }) {
      ...projectImage
    }
    makers: file(relativePath: { eq: "projects/makers.jpg" }) {
      ...projectImage
    }
    pride: file(relativePath: { eq: "projects/pride.jpg" }) {
      ...projectImage
    }
    ddb: file(relativePath: { eq: "projects/ddb.jpg" }) {
      ...projectImage
    }
    antarctica: file(relativePath: { eq: "projects/antarctica.jpg" }) {
      ...projectImage
    }
    soniaby: file(relativePath: { eq: "projects/soniaby.jpg" }) {
      ...projectImage
    }
    vw: file(relativePath: { eq: "projects/vw.jpg" }) {
      ...projectImage
    }
    gravity: file(relativePath: { eq: "projects/gravity.jpg" }) {
      ...projectImage
    }
    tippex: file(relativePath: { eq: "projects/tippex.jpg" }) {
      ...projectImage
    }
    adidas: file(relativePath: { eq: "projects/adidas.jpg" }) {
      ...projectImage
    }
    lglc: file(relativePath: { eq: "projects/lglc.jpg" }) {
      ...projectImage
    }
    battlefield: file(relativePath: { eq: "projects/battlefield.jpg" }) {
      ...projectImage
    }
  }
`

export const projectImage = graphql`
  fragment projectImage on File {
    childImageSharp {
      fluid(maxWidth: 615, maxHeight: 411) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export default IndexPage
